import React, { useState } from 'react';

const Header = () => {
    const links = [
        { href: "resume", text: "À mon propos" },
        { href: "contact", text: "Contact" },
    ];

    const [hoveredLink, setHoveredLink] = useState(null);

    return (
        <header>
            <a href="/" className='difference'>
                <img src="images/anthonytcha-logo.png" alt="Logo d'Anthony Tcha" className="monogram invert" />
            </a>
            <div className='links-container'>
                {links.map((link, index) => (
                    <a
                        key={index}
                        href={link.href}
                        className={`links uppercase ${hoveredLink === index ? 'hovered' : 'no-hover'}`}
                        onMouseEnter={() => setHoveredLink(index)}
                        onMouseLeave={() => setHoveredLink(null)}
                    >
                        {link.text}
                    </a>
                ))}
            </div>
        </header>
    );
};

export default Header;