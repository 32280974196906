import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSwipeable } from 'react-swipeable';

const Modal = ({ isOpen, onClose, imgSrc, imgAlt, videoUrl, onNext, onPrev, nextImgSrc, prevImgSrc }) => {
    const [offset, setOffset] = useState(0);
    const [isSwiping, setIsSwiping] = useState(false);
    const [isLocked, setIsLocked] = useState(false); // Utiliser un verrou pour éviter des appels multiples
    const [startX, setStartX] = useState(0);
    const [startY, setStartY] = useState(0);
    const [fadeIn, setFadeIn] = useState(false);
    const contentRef = useRef(null);

    const handleNext = useCallback(() => {
        if (!isLocked && typeof onNext === 'function') {
            setIsLocked(true); // Verrouiller le swipe
            onNext();
            resetScroll();
            setTimeout(() => setIsLocked(false), 250); // Délai pour débloquer le swipe
        }
    }, [onNext, isLocked]);

    const handlePrev = useCallback(() => {
        if (!isLocked && typeof onPrev === 'function') {
            setIsLocked(true); // Verrouiller le swipe
            onPrev();
            resetScroll();
            setTimeout(() => setIsLocked(false), 250); // Délai pour débloquer le swipe
        }
    }, [onPrev, isLocked]);

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'ArrowRight') {
            e.preventDefault();
            if (onNext) handleNext();
        } else if (e.key === 'ArrowLeft') {
            e.preventDefault();
            if (onPrev) handlePrev();
        }
    }, [onNext, onPrev, handleNext, handlePrev]);

    useEffect(() => {
        if (isOpen) {
            setFadeIn(true);
            window.addEventListener('keydown', handleKeyDown);
        } else {
            setFadeIn(false);
            window.removeEventListener('keydown', handleKeyDown);
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, handleKeyDown]);

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            onClose();
        }
    };

    const resetSwipe = () => {
        setIsSwiping(false);
        setOffset(0);
    };

    const resetScroll = () => {
        if (contentRef.current) {
            contentRef.current.scrollTo(0, 0);
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: handleNext, // appelle handleNext pour le swipe à gauche
        onSwipedRight: handlePrev, // appelle handlePrev pour le swipe à droite
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    });

    const handleStart = (e) => {
        e.preventDefault();
        setIsSwiping(true);
        const touch = e.touches ? e.touches[0] : e;
        setStartX(touch.clientX);
        setStartY(touch.clientY);
    };

    const handleMove = (e) => {
        if (!isSwiping) return;
        const touch = e.touches ? e.touches[0] : e;
        const deltaX = touch.clientX - startX;
        const deltaY = touch.clientY - startY;

        if (Math.abs(deltaX) > Math.abs(deltaY)) {
            e.preventDefault();
            setOffset(deltaX);
        }
    };

    const handleEnd = () => {
        if (Math.abs(offset) > 50) {
            offset > 0 ? handlePrev() : handleNext();
        }
        resetSwipe();
    };

    if (!isOpen) return null;

    const opacity = 1 - Math.min(Math.abs(offset) / (window.innerWidth / 2), 1);
    const transformStyle = {
        transform: `translateX(${offset}px)`,
        transition: isSwiping ? 'none' : 'transform 0.25s ease, opacity 0.25s ease',
        position: 'relative',
        opacity: opacity,
    };

    const fadeStyle = {
        opacity: fadeIn ? 1 : 0,
        transition: 'opacity 0.2s ease-in-out',
    };

    return (
        <div className="modal-overlay" onClick={handleOverlayClick} {...handlers}>
            <button className="modal-button sans-serif regular left" onClick={(e) => { e.stopPropagation(); handlePrev(); }}>&#8592;</button>
            <button className="modal-button sans-serif regular right" onClick={(e) => { e.stopPropagation(); handleNext(); }}>&#8594;</button>
            <div
                className="modal-content"
                style={transformStyle}
                ref={contentRef}
                onTouchStart={handleStart}
                onTouchMove={handleMove}
                onTouchEnd={handleEnd}
                onMouseDown={handleStart}
                onMouseMove={handleMove}
                onMouseUp={handleEnd}
                onMouseLeave={handleEnd}
            >
                {videoUrl ? (
                    <iframe
                        width="100%"
                        height="315"
                        src={videoUrl}
                        title="Video"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    ></iframe>
                ) : (
                    <>
                        {prevImgSrc && (
                            <img
                                src={`../${prevImgSrc}`}
                                alt={imgAlt}
                                style={{ cursor: 'grab', ...fadeStyle, position: 'absolute', left: '-100%', width: '100%' }}
                            />
                        )}
                        <img
                            src={`../${imgSrc}`}
                            alt={imgAlt}
                            style={{ cursor: 'grab', ...fadeStyle }}
                        />
                        {nextImgSrc && (
                            <img
                                src={`../${nextImgSrc}`}
                                alt={imgAlt}
                                style={{ cursor: 'grab', ...fadeStyle, position: 'absolute', right: '-100%', width: '100%' }}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default Modal;