import React from 'react';
import { projects } from '../data/projectData';
import { useParams } from 'react-router-dom';

const PrevNextProject = () => {
    const { id } = useParams();
    const currentIndex = projects.findIndex(p => p.link === id);

    // Obtenir le projet précédent et suivant
    const previousProject = projects[currentIndex - 1];
    const nextProject = projects[currentIndex + 1];

    return (
        <section className="prev-next-project-container">

            {nextProject && (
                <div className="prev-next-project-span" style={{ flex: '1' }}>
                    <a href={`/${nextProject.link}`}>
                        <div className="prev-next-project-content">
                            <p className="sans-serif gray regular">&larr; Projet précédent</p>
                            <h3 className="display black">{nextProject.title}</h3>
                            <p className="display yellow small-text regular">{nextProject.tags}</p>
                            <hr />
                            <p className="sans-serif gray regular">{nextProject.description}</p>
                        </div>
                    </a>
                </div>
            )}
            {previousProject && (
                <div className="prev-next-project-span" style={{ flex: '1' }}>
                    <a href={`/${previousProject.link}`}>
                        <div className="prev-next-project-content">
                            <p className="sans-serif gray regular">Projet suivant &rarr;</p>
                            <h3 className="display black">{previousProject.title}</h3>
                            <p className="display yellow small-text regular">{previousProject.tags}</p>
                            <hr />
                            <p className="sans-serif gray regular">{previousProject.description}</p>
                        </div>
                    </a>
                </div>
            )}
        </section>
    );
};

export default PrevNextProject;