import React from 'react';
import { projects } from '../data/projectData';

const ProjectGrid = () => {
  return (
    <section className="project-grid">
      {projects.map((project, index) => (
        <div className="project-grid-container" key={index}>
          <a href={`/${project.link}`}>
            <div className="project-grid-image-container">
              <img src={project.imgSrc} alt={`Logo de ${project.title}`} className="project-grid-image" />
            </div>
            <div className="project-grid-text-container">
              <h3 className="display black">{project.title}</h3>
              <p className="display yellow small-text regular">{project.tags}</p>
              <hr />
              <p className="sans-serif gray regular">{project.description}</p>
            </div>
          </a>
        </div>
      ))}
    </section>
  );
};

export default ProjectGrid;