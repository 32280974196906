import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';

const Contact = () => {
    const [formData, setFormData] = useState({
        fullName: '',
        company: '',
        email: '',
        subject: '',
        message: '',
        rgpd: false,
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const { fullName, company, email, subject, message, rgpd } = formData;

        if (!rgpd) {
            alert("Vous devez accepter la politique de confidentialité.");
            return;
        }

        const data = {
            fullName,
            company,
            email,
            subject,
            message,
        };

        emailjs.send('service_4i2u21m', 'template_7jmj5ud', data, 'lgMnUX8CZ4qZo1IeK')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert("Merci pour votre message !");
                setFormData({ fullName: '', company: '', email: '', subject: '', message: '', rgpd: false });
            }, (err) => {
                console.error('FAILED...', err);
                alert("Aïe, une erreur s'est produite. Essayez à nouveau.");
            });
    };

    return (
        <section>
            <img src="images/ma-photo-cropped.jpg" alt="Portrait tronqué d'Anthony TCHA" className="project-image" />

            <form onSubmit={handleSubmit} className="contact-form">
                <h1 className="display">Contactez-moi</h1>
                <p className='display yellow small-text'>C'est par ici pour m'envoyer un message.</p>

                <input
                    type="text"
                    name="fullName"
                    placeholder="Nom complet *"
                    value={formData.fullName}
                    onChange={handleChange}
                    required
                    className="form-input sans-serif medium-text"
                />
                <input
                    type="text"
                    name="company"
                    placeholder="Société (facultatif)"
                    value={formData.company}
                    onChange={handleChange}
                    className="form-input sans-serif medium-text"
                />
                <input
                    type="email"
                    name="email"
                    placeholder="Adresse mail *"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="form-input sans-serif medium-text"
                />
                <select
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                    className="form-input sans-serif medium-text">
                    <option value="" disabled>Objet *</option>
                    <option value="Demande de renseignements">Demande de renseignements</option>
                    <option value="Collaboration sur un projet">Collaboration sur un projet</option>
                    <option value="Demande de devis">Demande de devis</option>
                    <option value="Revue de portfolio">Revue de portfolio</option>
                    <option value="Création d'identité visuelle">Création d'identité visuelle</option>
                    <option value="Design graphique pour un événement">Design graphique pour un événement</option>
                    <option value="Consultation en branding">Consultation en branding</option>
                    <option value="Proposition de partenariat">Proposition de partenariat</option>
                    <option value="Autre">Autre</option>
                </select>
                <textarea
                    name="message"
                    placeholder="Votre message *"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    className="form-textarea sans-serif medium-text"
                />
                <label className="sans-serif medium-text">
                    <input
                        type="checkbox"
                        name="rgpd"
                        checked={formData.rgpd}
                        onChange={handleChange}
                        required
                        className="form-input"
                    /> J'accepte la <a href="/privacy-policy" className="project-links small-text no-hover" target="_blank" rel="noopener noreferrer" >politique de confidentialité <FontAwesomeIcon icon={faExternalLinkAlt} size="s" /></a> *</label>
                <button type="submit" className="form-button display medium-text">Envoyer</button>
            </form>
        </section>
    );
};

export default Contact;