export const projects = [
    {
        title: "KURO NO MASTER",
        link: "kuronomaster",
        description: "Le projet de ma vie. Mon manga interactif.",
        imgSrc: "images/project/kuronomaster/kuro-no-master-logo.webp",
        imgAlt: "Logo du manga KURO NO MASTER",
        href: "https://www.kuronomaster.com",
        url: "kuronomaster.com",
        tags: "Identité visuelle • Illustration • Édition • Développement • Musique • Webdesign",
        sections: [
            {
                imgSrc: "images/project/kuronomaster/kuro-no-master-lucien-couverture.webp",
                imgAlt: "Mockup, couverture de KURO NO MASTER",
            }, {
                content: [
                    { title: "Le concept", text: "KURO NO MASTER est un manga interactif en ligne où les choix des lecteurs influencent les histoires de cinq jeunes devenus Porteurs de Montres de Pouvoir, naviguant entre dilemmes moraux et responsabilités dans un monde moderne et fantastique." },
                    { title: "Synopsis", text: "« Existant depuis l’aube de l’humanité, les Montres de Pouvoir octroient des capacités altérant la réalité à leurs Porteurs, des individus d'exception dont les noms sont encore gravés dans les mémoires. Aujourd’hui, ces cinq jeunes ont été choisis pour être leurs Porteurs. Ils prendront conscience de leur responsabilité et devront faire des choix cruciaux qui impacteront l’Histoire. »" },
                    { title: "Arcs narratifs", text: "Le manga se décline en cinq arcs autonomes, chacun centré sur un protagoniste. À travers des chapitres interactifs, les décisions des lecteurs influencent le récit. Chaque personnage fait face à des dilemmes moraux et des traumatismes, rendant leur parcours et celui des lecteurs véritablement unique." }
                ],
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-scarlett-taille-baton.webp",
                imgAlt: "Scarlett qui aiguise un couteau",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-justine-chang-portrait.webp",
                imgAlt: "Portrait de Justine",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-maryse-chang-portrait.webp",
                imgAlt: "Portrait de Maryse",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-remi-pari.webp",
                imgAlt: "Remi joue à pile ou face",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-user-experience.webp",
                imgAlt: "L'UI, l'interface utilisateur épurée de KURO NO MASTER",
            }, {
                content: [
                    {
                        title: "La technique", text: "Depuis 2015, je développe ce projet seul. Les lecteurs peuvent débloquer et réécrire des chapitres en fonction de leurs choix. L’histoire s’articule autour de cinq personnages, chacun disposant d’une dizaine de chapitres avec des embranchements narratifs basés sur les décisions du public."
                    },
                    { title: "Les illustrations", text: "Les illustrations sont faites par moi-même, en nuances de gris semi-réalistes sans encrage, afin de saisir le caractère viscéral des expressions." },
                    { title: "L'interactivité", text: "Les lecteurs avancent dans l’histoire en cliquant sur les vignettes, validant leurs choix à chaque carrefour narratif. Un système visuel indique les vignettes actives et inactives, garantissant des décisions claires et responsables, sans jugements arbitraires." }
                ],
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-website.webp",
                imgAlt: "La page d'accueil du site du manga KURO NO MASTER",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-lucien-graffiti.webp",
                imgAlt: "Mockup, couverture de KURO NO MASTER",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-scarlett-jogging.webp",
                imgAlt: "Scarlett au jogging",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-logo-construct.webp",
                imgAlt: "Construction du logo de KURO NO MASTER",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-steve-o-keeffe-portrait.webp",
                imgAlt: "Portrait de Steve",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-remi-apres-rasage.webp",
                imgAlt: "Remi après le rasage",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-rachel-decker-portrait.webp",
                imgAlt: "Portrait de Rachel",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-scarlett-ferme.webp",
                imgAlt: "Scarlett dans la grange",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-lucien-sonia-cole-marchent.webp",
                imgAlt: "Lucien, Sonia et Cole marchent ensemble",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-laurent-de-kern-portrait.webp",
                imgAlt: "Portrait de Laurent",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-sonia-et-alina-belinskaya.webp",
                imgAlt: "Alina et Sonia",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-yulia-enlace-sonia.webp",
                imgAlt: "Yulia et Sonia s'enlacent",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-yulia-fenetre-nijni-novgorod.webp",
                imgAlt: "Yulia devant la fenêtre",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-sonia-belinskaya.webp",
                imgAlt: "Sonia avec cicatrices au visage",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-sonia-10-ans.webp",
                imgAlt: "Sonia avec cicatrices au visage",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-dmitry-belinsky.webp",
                imgAlt: "Portrait de Dmitri",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-yulia-belinskaya-cd.webp",
                imgAlt: "Character Design de Yulia",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-sonia-blessee-cd.webp",
                imgAlt: "Character Design de Sonia enfant",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-sonia-ado-cd.webp",
                imgAlt: "Character Design de Sonia ado",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-sonia-joue-du-violon-dans-la-chambre.webp",
                imgAlt: "Sonia joue du violon dans sa chambre",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-character-design-clothes.webp",
                imgAlt: "Character design des protagonistes du manga KURO NO MASTER",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-sonia-le-son-de-mes-reves.webp",
                imgAlt: "Sonia flotte dans les airs",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-cole-souriant.webp",
                imgAlt: "Cole qui sourit",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-rachel-decker-portrait-2.webp",
                imgAlt: "Rachel se plaint",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-cole-carthasis.webp",
                imgAlt: "Cole au travail",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-montre-du-destin.webp",
                imgAlt: "Étude de la Montre du Destin",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-montre-du-temps.webp",
                imgAlt: "Montre du Temps portée par Lucien Chang",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-montre-de-lespace.webp",
                imgAlt: "Montre de l'Espace portée par Sonia Bellevue",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-lucien-s-echappe.webp",
                imgAlt: "Lucien s'échappe",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-character-design-classy.webp",
                imgAlt: "Character Design classe des protagonistes",
            }, {
                imgSrc: "images/project/kuronomaster/kuro-no-master-cerena-cadeau.webp",
                imgAlt: "Cadeau pour Cerena",
            },
        ]
    }, {
        title: "Association Hmong Rennes 35",
        link: "association-hmong-rennes-35",
        description: "Association pour la préservation, le partage et l'héritage de la culture Hmong.",
        imgSrc: "images/project/association-hmong-rennes-35/association-hmong-rennes-35-logo.webp",
        imgAlt: "Logo de l'Association Culturelle et Traditionnelle Hmong Rennes 35",
        tags: "Identité visuelle. Print • Évènementiel",
        sections: [
            {
                imgSrc: "images/project/association-hmong-rennes-35/association-hmong-rennes-35-flyer-nouvel-an-2025.webp",
                imgAlt: "Flyer pour le Nouvel An Hmong 2025",
            }, {
                content: [
                    {
                        title: "L'association",
                        text: "L’Association Culturelle et Traditionnelle Hmong Rennes 35 a pour vocation de préserver et transmettre la richesse de la culture et des traditions Hmong. Elle offre un espace de partage intergénérationnel en organisant des événements culturels et des ateliers éducatifs. L’association fait découvrir au public rennais le patrimoine Hmong et favorise les échanges interculturels."
                    },
                    {
                        title: "Le Nouvel An Hmong",
                        text: "Le Nouvel An Hmong, événement majeur de la communauté, célèbre la fin des récoltes de l'année. Ce moment de renouveau réunit les générations dans la fête, la danse et la convivialité, tout en mettant à l'honneur les rituels ancestraux et la richesse de la culture Hmong."
                    }
                ]
            }, {
                imgSrc: "images/project/association-hmong-rennes-35/association-hmong-rennes-35-flyer-nouvel-an-2024.webp",
                imgAlt: "Flyer pour le Nouvel An Hmong 2024",
            }, {
                imgSrc: "images/project/association-hmong-rennes-35/association-hmong-rennes-35-tampon.webp",
                imgAlt: "Tampon de l'association Hmong Renns 35",
            }, {
                imgSrc: "images/project/association-hmong-rennes-35/association-hmong-rennes-35-affiche-tombola.webp",
                imgAlt: "Affiche pour la promotion de la tombola du Nouvel An Hmong 2024",
            }, {
                imgSrc: "images/project/association-hmong-rennes-35/association-hmong-rennes-35-bulletin-special-2022.webp",
                imgAlt: "Couverture du bulletin spécial de journées d'hommage aux morts 2022",
            }
        ]
    },
    {
        title: "Les Lucioles Silencieuses",
        link: "les-lucioles-silencieuses",
        description: "Évènement de Street Art urbain nantais.",
        imgSrc: "images/project/les-lucioles-silencieuses/lucioles-silencieuses-logo.webp",
        imgAlt: "Logo de l'évènement des Lucioles Silencieuses",
        tags: "Identité visuelle • Print • Vidéo • Évènementiel",
        sections: [
            {
                imgSrc: "images/project/les-lucioles-silencieuses/lucioles-silencieuses-billboard-mockup.webp",
                imgAlt: "Affiche panneau pour Les Lucioles Silencieuses",
            }, {
                imgSrc: "images/project/les-lucioles-silencieuses/lucioles-silencieuses-affiche-a3.webp",
                imgAlt: "Affiche A3 pour Les Lucioles Silencieuses",
            }, {
                imgSrc: "images/project/les-lucioles-silencieuses/lucioles-silencieuses-flyer-a5.webp",
                imgAlt: "Flyer A5 pour Les Lucioles Silencieuses",
            },
        ]
    }, {
        title: "Cadence",
        link: "cadence",
        description: "Application de cadavre exquis musical.",
        imgSrc: "images/project/cadence/cadence-logo.webp",
        imgAlt: "Logo de l'application web Cadence",
        tags: "Identité visuelle • Maquettes • Communication • Motion Design • Webdesign",
        sections: [
            {
                imgSrc: "images/project/cadence/cadence-logo-white.webp",
                imgAlt: "Logo de Cadence sur fond blanc",
            }, {
                content: [
                    {
                        title: "Le concept",
                        text: "Cadence reprend le concept de Cadavre Exquis et l'applique à la musique. C'est un jeu créatif où chaque musicien reprend la dernière note d’un morceau pour créer une nouvelle composition collective.",
                    }, {
                        title: "Continuez la musique",
                        text: "Chaque participant utilise la note finale du précédent comme point de départ, ajoutant sa propre touche pour transformer le morceau au fil des contributions. Ce processus stimule la créativité en encourageant l’exploration de nouvelles idées musicales, tout en renforçant la collaboration et l’écoute entre les musiciens. En traversant différents styles et genres, le projet offre une expérience unique et dynamique, célébrant l’improvisation et la création collective.",
                    }
                ]
            }, {
                imgSrc: "images/project/cadence/cadence-maquette-1.webp",
                imgAlt: "Maquette de la page d'accueil de Cadence",
            }, {
                imgSrc: "images/project/cadence/cadence-maquette-2.webp",
                imgAlt: "Maquette des cards des musiques de Cadence",
            }, {
                imgSrc: "images/project/cadence/cadence-maquette-3.webp",
                imgAlt: "Maquette du studio d'enregistrement de Cadence",
            }, {
                imgSrc: "images/project/cadence/cadence-maquette-ipad.webp",
                imgAlt: "Utilisation réelle de Cadence sur iPad",
            }, {
                imgSrc: "images/project/cadence/cadence-affiche-1.webp",
                imgAlt: "Batteur qui commence la musique",
            }, {
                imgSrc: "images/project/cadence/cadence-affiche-2.webp",
                imgAlt: "Saxophoniste qui continue la musique",
            }, {
                imgSrc: "images/project/cadence/cadence-affiche-3.webp",
                imgAlt: "Guitariste qui termine la musique",
            }, {
                imgSrc: "images/project/cadence/cadence-feed-1.webp",
                imgAlt: "Trompettiste qui commence la musique",
            }, {
                imgSrc: "images/project/cadence/cadence-feed-2.webp",
                imgAlt: "Guitariste qui continue la musique",
            }, {
                imgSrc: "images/project/cadence/cadence-feed-3.webp",
                imgAlt: "Joueuse de shamisen qui termine la musique",
            }, {
                imgSrc: "images/project/cadence/cadence-logo-white-wireframe.webp",
                imgAlt: "Composition du logo de Cadence",
            },
            {
                imgSrc: 'images/project/cadence/cadence-trailer.webp',
                videoUrl: 'https://www.youtube.com/watch?v=YMKab5Yxmug' // URL de la vidéo
            }
        ]
    },
    {
        title: "TAIGA",
        link: "taiga",
        description: "Identité de marque de manteaux d'hiver.",
        imgSrc: "images/project/taiga/taiga-headline.webp",
        imgAlt: "Logo de la marque de vêtements d'hiver TAIGA",
        tags: "Identité visuelle • Print • Mockups",
        sections: [
            {
                imgSrc: "images/project/taiga/taiga-nombre-or.webp",
                imgAlt: "Conception du logo de TAIGA avec le nombre d'or",
            }, {
                imgSrc: "images/project/taiga/taiga-affiche-1.webp",
                imgAlt: "Affiche des manteaux TAIGA",
            }, {
                imgSrc: "images/project/taiga/taiga-affiche-2.webp",
                imgAlt: "Affiche des manteaux TAIGA",
            }, {
                imgSrc: "images/project/taiga/taiga-box.webp",
                imgAlt: "Mockup de coffret de vêtements TAIGA",
            }, {
                imgSrc: "images/project/taiga/taiga-cards.webp",
                imgAlt: "Mockup de cartes de visite TAIGA",
            }, {
                imgSrc: "images/project/taiga/taiga-zipper.webp",
                imgAlt: "Gravure TAIGA sur la fermeture éclair",
            }
        ]
    }, {
        title: "Pérégrination",
        link: "peregrination",
        description: "Notre petitesse en peinture face à l'environnement.",
        imgSrc: "images/project/peregrination/peregrination.webp",
        imgAlt: "Peinture jaune et violette de désert",
        tags: "Illustration",
        sections: [
            {
                imgSrc: "images/project/peregrination/peregrination-1.webp",
                imgAlt: "Peinture acrylique de la petitesse humaine face à au paysage naturel",
            }, {
                imgSrc: "images/project/peregrination/peregrination-2.webp",
                imgAlt: "Peinture acrylique de la petitesse humaine face à au paysage naturel",
            }, {
                imgSrc: "images/project/peregrination/peregrination-3.webp",
                imgAlt: "Peinture acrylique de la petitesse humaine face à au paysage naturel",
            }, {
                imgSrc: "images/project/peregrination/peregrination-4.webp",
                imgAlt: "Peinture acrylique de la petitesse humaine face à au paysage naturel",
            }, {
                imgSrc: "images/project/peregrination/peregrination-5.webp",
                imgAlt: "Peinture acrylique de la petitesse humaine face à au paysage naturel",
            }, {
                imgSrc: "images/project/peregrination/peregrination-6.webp",
                imgAlt: "Peinture acrylique de la petitesse humaine face à au paysage naturel",
            }, {
                imgSrc: "images/project/peregrination/peregrination-7.webp",
                imgAlt: "Peinture acrylique de la petitesse humaine face à au paysage naturel",
            }, {
                imgSrc: "images/project/peregrination/peregrination-8.webp",
                imgAlt: "Peinture acrylique de la petitesse humaine face à au paysage naturel",
            }, {
                imgSrc: "images/project/peregrination/peregrination-9.webp",
                imgAlt: "Peinture acrylique de la petitesse humaine face à au paysage naturel",
            },
        ]
    }, {
        title: "Alternate Movie Posters",
        link: "alternate-movie-posters",
        description: "Affiches alternatives de films.",
        imgSrc: "images/project/alternate-movie-poster/alternate-movie-poster-interstellar.webp",
        imgAlt: "Affiche alternative d'Interstellar",
        tags: "Infographie",
        sections: [
            {
                imgSrc: "images/project/alternate-movie-poster/alternate-movie-poster-attack-on-titan.webp",
                imgAlt: "Affiche alternative de l'Attaque des Titans",
            }, {
                imgSrc: "images/project/alternate-movie-poster/alternate-movie-poster-inception.webp",
                imgAlt: "Affiche alternative d'Inception",
            }, {
                imgSrc: "images/project/alternate-movie-poster/alternate-movie-poster-green-book.webp",
                imgAlt: "Affiche alternative de Green Book",
            },
        ]
    }, {
        title: "Cristaux",
        link: "cristaux",
        description: "Triangularisation vectorielle de minéraux.",
        imgSrc: "images/project/cristaux/quartz-noir.webp",
        imgAlt: "Illustration de quartz noir triangularisé",
        tags: "Illustration",
        sections: [
            {
                imgSrc: "images/project/cristaux/amethyste.webp",
                imgAlt: "Triangularisation d'améthyste",
            }, {
                imgSrc: "images/project/cristaux/calcite.webp",
                imgAlt: "Triangularisation de calcite",
            }, {
                imgSrc: "images/project/cristaux/cinabre.webp",
                imgAlt: "Triangularisation de cinabre",
            }, {
                imgSrc: "images/project/cristaux/diamant.webp",
                imgAlt: "Triangularisation de diamant",
            }, {
                imgSrc: "images/project/cristaux/emeraude.webp",
                imgAlt: "Triangularisation d'émeraude",
            }, {
                imgSrc: "images/project/cristaux/fluorite.webp",
                imgAlt: "Triangularisation de fluorite",
            }, {
                imgSrc: "images/project/cristaux/lapis-lazuli.webp",
                imgAlt: "Triangularisation de lapis lazuli",
            }, {
                imgSrc: "images/project/cristaux/malachite.webp",
                imgAlt: "Triangularisation de malachite",
            }, {
                imgSrc: "images/project/cristaux/phosphophylite.webp",
                imgAlt: "Triangularisation de phosphophylite",
            }, {
                imgSrc: "images/project/cristaux/quartz.webp",
                imgAlt: "Triangularisation de quartz",
            }, {
                imgSrc: "images/project/cristaux/roche.webp",
                imgAlt: "Triangularisation de roche",
            }, {
                imgSrc: "images/project/cristaux/rubis.webp",
                imgAlt: "Triangularisation de rubis",
            }, {
                imgSrc: "images/project/cristaux/saphir.webp",
                imgAlt: "Triangularisation de saphir",
            }, {
                imgSrc: "images/project/cristaux/topaze.webp",
                imgAlt: "Triangularisation de topaze",
            }
        ]
    }, {
        title: "Suspects sinistres",
        link: "suspects-sinistres",
        description: "Portraits photographiques low-key.",
        imgSrc: "images/project/suspects-sinistres/demon.webp",
        imgAlt: "Visage en low-key",
        tags: "Photographie • Retouche photo",
        sections: [
            {
                imgSrc: "images/project/suspects-sinistres/bas-relief.webp",
                imgAlt: "Suspect sinistre: Bas-relief",
            }, {
                imgSrc: "images/project/suspects-sinistres/face.webp",
                imgAlt: "Suspect sinistre: Face",
            }, {
                imgSrc: "images/project/suspects-sinistres/headshot.webp",
                imgAlt: "Suspect sinistre: Headshot",
            }, {
                imgSrc: "images/project/suspects-sinistres/heros-dechu.webp",
                imgAlt: "Suspect sinistre: Héros déchu",
            }, {
                imgSrc: "images/project/suspects-sinistres/malice.webp",
                imgAlt: "Suspect sinistre: Malice",
            }, {
                imgSrc: "images/project/suspects-sinistres/reveil.webp",
                imgAlt: "Suspect sinistre: Réveil",
            }, {
                imgSrc: "images/project/suspects-sinistres/ricanement.webp",
                imgAlt: "Suspect sinistre: Ricanement",
            }, {
                imgSrc: "images/project/suspects-sinistres/rictus.webp",
                imgAlt: "Suspect sinistre: Rictus",
            }, {
                imgSrc: "images/project/suspects-sinistres/statue.webp",
                imgAlt: "Suspect sinistre: Statue",
            }, {
                imgSrc: "images/project/suspects-sinistres/tableau-vivant.webp",
                imgAlt: "Suspect sinistre: Tableau vivant",
            }, {
                imgSrc: "images/project/suspects-sinistres/zombie.webp",
                imgAlt: "Suspect sinistre: Zombie",
            },
        ]
    }, {
        title: "Conception de logos",
        link: "logos",
        description: "Création en tous genres de logotypes.",
        imgSrc: "images/project/logo/logo-lhonore.webp",
        imgAlt: "Logo de l'évènement de mariage L'Honoré",
        tags: "Identité visuelle",
        sections: [
            {
                imgSrc: "images/project/logo/new-delhi-street-art-logo.webp",
                imgAlt: "Logo pour la baleine",
            }, {
                imgSrc: "images/project/logo/logo-lion.webp",
                imgAlt: "Logo pour le lion",
            }, {
                imgSrc: "images/project/logo/logo-otter.webp",
                imgAlt: "Logo pour la loutre",
            }, {
                imgSrc: "images/project/logo/logo-spider.webp",
                imgAlt: "Logo pour l'araignée",
            }, {
                imgSrc: "images/project/logo/logo-turtle.webp",
                imgAlt: "Logo pour la tortue",
            }, {
                imgSrc: "images/project/logo/logo-whale.webp",
                imgAlt: "Logo pour la baleine",
            }
        ]
    }, {
        title: "Le Crénom",
        link: "le-crenom",
        description: "Restaurant parisien depuis 1930.",
        imgSrc: "images/project/le-crenom/le-crenom-logo.webp",
        imgAlt: "Le logo du restaurant Le Crénom",
        tags: "Identité visuelle • Print • Signalétique",
        sections: [
            {
                imgSrc: "images/project/le-crenom/le-crenom-carte-2000-jaune.webp",
                imgAlt: "Le menu du restaurant Le Crénom",
            },
            {
                imgSrc: "images/project/le-crenom/le-crenom-carte-mockup-2000.webp",
                imgAlt: "Mockup du menu du restaurant Le Crénom",
            },
            {
                imgSrc: "images/project/le-crenom/le-crenom-facade-logo-2000.webp",
                imgAlt: "Signalétique de façade du restaurant Le Crénom",
            },
        ]
    }, {
        title: "Virtualisation",
        link: "virtualisation",
        description: "Triangularisation de figures humaines.",
        imgSrc: "images/project/virtualisation/tango-dancers.webp",
        imgAlt: "Danseurs de tango",
        tags: "Illustration",
        sections: [
            {
                imgSrc: "images/project/virtualisation/baby-running.webp",
                imgAlt: "Bébé qui court",
            }, {
                imgSrc: "images/project/virtualisation/girl-feet.webp",
                imgAlt: "Pied de fille",
            }, {
                imgSrc: "images/project/virtualisation/walking-hand.webp",
                imgAlt: "Main marchante",
            }, {
                imgSrc: "images/project/virtualisation/jumping-boy.webp",
                imgAlt: "Enfant qui saute",
            }, {
                imgSrc: "images/project/virtualisation/slavic-push-ups.webp",
                imgAlt: "Pompes slaves",
            }, {
                imgSrc: "images/project/virtualisation/asian-beauty.webp",
                imgAlt: "Beauté asiatique",
            }, {
                imgSrc: "images/project/virtualisation/the-fall.webp",
                imgAlt: "La chute",
            }, {
                imgSrc: "images/project/virtualisation/tired-man.webp",
                imgAlt: "Homme fatigué",
            }, {
                imgSrc: "images/project/virtualisation/usain-bolt.webp",
                imgAlt: "Usain Bolt",
            }
        ]
    }, {
        title: "Faune impossible",
        link: "faune-impossible",
        description: "Des animaux improbables.",
        imgSrc: "images/project/faune-impossible/penguin-dolphin.webp",
        imgAlt: "Dauphins manchots",
        tags: "Retouche photo",
        sections: [
            {
                imgSrc: "images/project/faune-impossible/banana-orca.webp",
                imgAlt: "Orque banane",
            }, {
                imgSrc: "images/project/faune-impossible/panda-girl.webp",
                imgAlt: "Fille panda",
            }, {
                imgSrc: "images/project/faune-impossible/impossible-love.webp",
                imgAlt: "Amour impossible entre lion et antilope",
            }, {
                imgSrc: "images/project/faune-impossible/jazzy-horsefish.webp",
                imgAlt: "Hippocampe saxophoniste",
            }, {
                imgSrc: "images/project/faune-impossible/brother-anteaters.webp",
                imgAlt: "Frères tamanoirs",
            }, {
                imgSrc: "images/project/faune-impossible/pasta-snail.webp",
                imgAlt: "Escargots en pâtes",
            }, {
                imgSrc: "images/project/faune-impossible/plant-snake.webp",
                imgAlt: "Serpents plantes",
            }, {
                imgSrc: "images/project/faune-impossible/steak-cow.webp",
                imgAlt: "Dans une vache on n'en voit que le steak",
            }, {
                imgSrc: "images/project/faune-impossible/bird-butterfly.webp",
                imgAlt: "Oiseau papillon",
            }
        ]
    }, {
        title: "Jeux de m'eau",
        link: "jeux-de-meau",
        description: "Mise en image d'expressions liées à l'eau.",
        imgSrc: "images/project/jeux-de-meau/se-mefier-de-leau-qui-dort.webp",
        imgAlt: "Illustration amusante d'un bonhomme qui va se faire engloutir par des vagues",
        tags: "Illustration",
        sections: [
            {
                imgSrc: "images/project/jeux-de-meau/bac-eau.webp",
                imgAlt: "Le bac c'est de l'eau",
            }, {
                imgSrc: "images/project/jeux-de-meau/coup-epee-eau.webp",
                imgAlt: "C'est un coup d'épée dans l'eau",
            }, {
                imgSrc: "images/project/jeux-de-meau/eau-peau-vin-vitalite.webp",
                imgAlt: "L'eau pour la peau, le vin pour la vitalité",
            }, {
                imgSrc: "images/project/jeux-de-meau/eau-renversee.webp",
                imgAlt: "L'eau renversée est difficile à rattraper",
            }, {
                imgSrc: "images/project/jeux-de-meau/jeter-eau.webp",
                imgAlt: "Se jeter à l'eau",
            }, {
                imgSrc: "images/project/jeux-de-meau/noyer-verre-eau.webp",
                imgAlt: "Se noyer dans un verre d'eau",
            }, {
                imgSrc: "images/project/jeux-de-meau/se-mefier-de-leau-qui-dort.webp",
                imgAlt: "Se méfier de l'eau qui dort",
            }, {
                imgSrc: "images/project/jeux-de-meau/tomber-eau.webp",
                imgAlt: "Tomber à l'eau",
            },
        ]
    }, {
        title: "Mister Coocky",
        link: "mister-coocky",
        description: "Plateforme d'articles pour animaux de compagnie.",
        imgSrc: "images/project/mister-coocky/mister-coocky-logo.webp",
        imgAlt: "Logo de l'application Mister Coocky",
        href: "https://mister-coocky.fr",
        url: "mister-coocky.fr",
        tags: "Identité visuelle • Print • Vidéo • Webdesign • Application • Réseaux sociaux",
        sections: [
            {
                imgSrc: "images/project/mister-coocky/animation-tuto-1-presentation.gif",
                imgAlt: "Mister Coocky recherche avec une loupe des articles pour animaux de compagnie",
            }, {
                imgSrc: "images/project/mister-coocky/animation-tuto-2-smr.gif",
                imgAlt: "Label Sain, Malin et Responsable avec leurs icônes flottantes",
            }, {
                imgSrc: "images/project/mister-coocky/animation-tuto-3-photo.gif",
                imgAlt: "Reconnaissance visuelle des espèces et races animales",
            }, {
                imgSrc: "images/project/mister-coocky/3-mc-flyer-btoc-VERSO.webp",
                imgAlt: "Flyer de Mister Coocky pour les particuliers",
            }, {
                imgSrc: "images/project/mister-coocky/5-mc-affiche-btoc.webp",
                imgAlt: "Affiche de Mister Coocky pour les particuliers",
            }, {
                imgSrc: "images/project/mister-coocky/mister_coocky_calendrier.webp",
                imgAlt: "Calendrier 2023 de Mister Coocky",
            }, {
                imgSrc: "images/project/mister-coocky/mister-coocky-mockup.webp",
                imgAlt: "Interface mobile de Mister Coocky",
            }, {
                imgSrc: "images/project/mister-coocky/7-mister_coocky_rollup.webp",
                imgAlt: "Kakemono de Mister Coocky",
            }, {
                imgSrc: "images/project/mister-coocky/mister-coocky-site-web.webp",
                imgAlt: "Kakemono de Mister Coocky",
            }, {
                imgSrc: "images/project/mister-coocky/signature-mail-mister-coocky.webp",
                imgAlt: "Signature mail de Mister Coocky",
            }, {
                imgSrc: "images/project/mister-coocky/mister-coocky-mockup.webp",
                imgAlt: "Signature mail de Mister Coocky",
            },
        ]
    }, {
        title: "Touddart Olives",
        link: "touddart-olives",
        description: "Site internet e-commerce de vente d'huile d'olives haut de gamme.",
        imgSrc: "images/project/touddart-olives/touddart-olives-logo.webp",
        imgAlt: "Logo de Touddart Olives",
        tags: "Identité visuelle • Branding • Webdesign • Maquette • Logo",
        sections: [
            {
                imgSrc: "images/project/touddart-olives/touddart-olives-bouteille-noire-etiquette-noire.webp",
                imgAlt: "Mockup de bouteille d'huile d'olive de Touddart Olives",
            }, {
                imgSrc: "images/project/touddart-olives/touddart-olives-bouteille-noire-etiquette-rouge.webp",
                imgAlt: "Mockup de bouteille d'huile d'olive de Touddart Olives",
            }, {
                imgSrc: "images/project/touddart-olives/touddart-olives-bouteille-trans-etiquette-verte.webp",
                imgAlt: "Mockup de bouteille d'huile d'olive de Touddart Olives",
            }, {
                imgSrc: "images/project/touddart-olives/touddart-olives-proposition-1.webp",
                imgAlt: "Proposition numéro 1 du logo de Touddart Olives",
            }, {
                imgSrc: "images/project/touddart-olives/touddart-olives-proposition-2.webp",
                imgAlt: "Proposition numéro 2 du logo de Touddart Olives",
            }, {
                imgSrc: "images/project/touddart-olives/touddart-olives-proposition-3.webp",
                imgAlt: "Proposition numéro 3 du logo de Touddart Olives",
            }, {
                imgSrc: "images/project/touddart-olives/touddart-olives-icones.webp",
                imgAlt: "Mockup de bouteille d'huile d'olive de Touddart Olives",
            }, {
                imgSrc: "images/project/touddart-olives/touddart-olives-maquette-site-web.webp",
                imgAlt: "Maquette de site de la homepage du site de Touddart Olives",
            },
        ]
    }, /*{
        title: "Grands Projets Digital Campus",
        link: "grands-projets-digital-campus",
        description: "Vidéo récap' des Grands Projets.",
        imgSrc: "images/project/grands-projets-digital-campus/logo-digital-campus.png",
        imgAlt: "Logo de Digital Campus",
        tags: "Motion design. Vidéo."
    }, {
        title: "Rezé Histoire",
        link: "reze-histoire",
        description: "Site vitrine associatif du sauvegarde du patrimoine historique de Rezé.",
        imgSrc: "images/project/reze-histoire/logo-reze-histoire.jpg",
        imgAlt: "Logo de l'association Rezé Histoire",
        href: "https://www.rezehistoire.fr",
        url: "rezehistoire.fr",
        tags: "Développement. Wordpress."
    }, {
        title: "Digital Portage",
        link: "digital-portage",
        description: "Maquette de site pour l'organisme de portage salarial Digital Portage",
        imgSrc: "images/project/digital-portage/logo-digital-portage.png",
        imgAlt: "Logo de Digital Portage",
        tags: "Identité visuelle. Maquettes. Webdesign. Logo."
    }, {
        title: "ISM-IAE",
        link: "ism-iae",
        description: "Réalisation de vidéos pour l'ISM-IAE.",
        imgSrc: "images/project/ism-iae/logo-ism-iae.jpg",
        imgAlt: "Logo de l'ISM-IAE",
        tags: "Vidéos. Motion Design."
    }, {
        title: "Rougier & Plé",
        link: "rougier-et-ple",
        description: "Maquette de site.",
        imgSrc: "images/project/rougier-&-ple/logo-rougier-et-ple.png",
        imgAlt: "Logo de Rougier & Plé",
        tags: "Maquettes. Refonte graphique. Webdesign."
    }, {
        title: "Rougier & Plé",
        link: "rougier-et-ple",
        description: "Maquette de site.",
        imgSrc: "images/project/rougier-&-ple/logo-rougier-et-ple.png",
        imgAlt: "Logo de Rougier & Plé",
        tags: "Maquettes. Refonte graphique. Webdesign."
    }, {
        title: "GreenTwins",
        link: "green-twins",
        description: "Site vitrine et webapp de monitoring et de gestion de l'énergie.",
        imgSrc: "images/project/greentwins/logo-green-twins.jpg",
        imgAlt: "Logo du projet GreenTwins",
        tags: "Webdesign. Identité visuelle. Print."
    }, {
        title: "UpCup'",
        link: "upcup",
        description: "Site évènement pour mugs personnalisés.",
        imgSrc: "images/project/upcup/upcup-logo-packshot.jpg",
        imgAlt: "Logo de la marque de tasse UpCup'",
        tags: "Webdesign."
    }, {
        title: "Colis",
        link: "colis",
        description: "Fanzine, satire de l'emballage.",
        imgAlt: "Illustration stylisé de tapis roulant de colis",
        imgSrc: "images/project/colis/colis.jpg",
        tags: "Print. Édition."
    }*/
];