import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { projects } from '../data/projectData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import PrevNextProject from './PrevNextProject';
import ProjectGrid from './ProjectGrid';
import Modal from './Modal';

const ProjectPage = () => {
    const { id } = useParams();
    const project = projects.find(p => p.link === id);
    const [isModalOpen, setModalOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [isHovered, setIsHovered] = useState(false);

    const openModal = (index) => {
        setCurrentImageIndex(index);
        setModalOpen(true);
    };

    const closeModal = () => setModalOpen(false);

    const changeImageIndex = (increment) => {
        if (project) {
            let newIndex = currentImageIndex;
            const totalSections = project.sections.length;

            do {
                newIndex = (newIndex + increment + totalSections) % totalSections; // Boucle dans les limites
            } while (!project.sections[newIndex]?.imgSrc); // Sauter les sections sans image

            setCurrentImageIndex(newIndex);
        }
    };

    const nextImage = () => changeImageIndex(1);
    const prevImage = () => changeImageIndex(-1);

    useEffect(() => {
        document.body.style.overflow = isModalOpen ? 'hidden' : '';
    }, [isModalOpen]);

    if (!project) {
        return (
            <section>
                <img src="images/404.png" alt="Erreur 404" className="project-image error-404" />
                <div className="project-header">
                    <h1 className="display">Vous vous êtes perdu ?</h1>
                    <p className="display yellow small-text">Dommage, mais ce n'est pas grave.</p>
                    <hr />
                    <p className="sans-serif gray">Il y a encore de belles choses à découvrir en dessous.</p>
                </div>
                <ProjectGrid />
            </section>
        );
    }

    return (
        <section>
            <img src={`../${project.imgSrc}`} alt={project.imgAlt} className="project-image" />
            <div className="project-header">
                <h1 className="display">{project.title}</h1>
                <p className="display yellow small-text">{project.tags}</p>
                <hr />
                <p className="sans-serif gray">{project.description}</p>
                {project.url && (
                    <a
                        href={project.href}
                        className={`project-links ${isHovered ? 'hovered' : 'no-hover'}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        {project.url}
                        {' '} {/* Ajoute un espace ici */}
                        <FontAwesomeIcon icon={faExternalLinkAlt} size="s" />
                    </a>
                )}
            </div>

            <div className="project-content">
                {project.sections?.map((section, index) => (
                    <div key={index}>
                        {section.content?.map((item, idx) => (
                            <div key={idx}>
                                <h2 className="display bold project-content-text">{item.title}</h2>
                                <p className="sans-serif regular project-content-text">{item.text}</p>
                                <hr />
                            </div>
                        ))}
                        {section.imgSrc && (
                            <img
                                src={`../${section.imgSrc}`}
                                alt={section.imgAlt}
                                className="project-image"
                                onClick={() => openModal(index)}
                            />
                        )}
                    </div>
                ))}
            </div>

            <PrevNextProject />
            {project.sections && (
                <Modal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    imgSrc={project.sections[currentImageIndex]?.imgSrc}
                    imgAlt={project.sections[currentImageIndex]?.imgAlt || ""}
                    onNext={nextImage}
                    onPrev={prevImage}
                />
            )}
        </section>
    );
};

export default ProjectPage;