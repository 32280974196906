import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const InfoSection = ({ title, children }) => (
    <div className='info-section'>
        <h2 className='display medium-text'>{title}</h2>
        <p className='sans-serif gray'>{children}</p>
    </div>
);

const PrivacyPolicy = () => {
    return (
        <section>
            <img
                src="images/ma-photo-cropped.jpg"
                alt="Portrait tronqué d'Anthony TCHA"
                className="project-image"
            />
            <div className='resume-container'>
                <h1 className='display'>Politique de Confidentialité</h1>
                <p className='display yellow small-text'>
                    Cette politique de confidentialité décrit comment je collecte, utilise et protège vos informations personnelles lors de votre visite sur le site anthonytcha.com. Je m'engage à garantir la confidentialité et la sécurité de vos données, que ce soit sur le site ou dans le cadre de mes services en tant que micro-entrepreneur en graphisme. Je vous invite à lire attentivement cette politique avant d'utiliser le site.
                </p>
                <hr />

                <InfoSection title="Les informations collectées">
                    <ul className='sans-serif gray'>
                        <li><strong>Données techniques:</strong> Adresse IP, type de navigateur, fuseau horaire, pages visitées, termes de recherche.</li>
                        <li><strong>Cookies:</strong> Fichiers pour améliorer votre expérience en mémorisant vos préférences.</li>
                        <li><strong>Formulaire de contact:</strong> Informations personnelles comme nom et e-mail.</li>
                        <li><strong>Commentaires et échanges:</strong> Informations relatives à vos demandes et projets.</li>
                    </ul>
                    <p className='sans-serif gray'>
                        Vous pouvez explorer mon portfolio en ligne sans identification. Cependant, certaines informations peuvent être collectées automatiquement ou fournies par vous.
                    </p>
                </InfoSection>

                <InfoSection title="Le but de la collecte de données">
                    <p className='sans-serif gray'>
                        La collecte de données vise à garantir la sécurité de vos informations. Je ne traite que les données nécessaires à la maintenance du site et je m'engage à ne pas vendre ni partager vos informations personnelles, sauf si la loi l'exige.
                    </p>
                </InfoSection>

                <InfoSection title="Vos droits">
                    <p className='sans-serif gray'>
                        Si vous résidez en Europe, vous disposez de plusieurs droits concernant vos informations personnelles. N'hésitez pas à me contacter pour toute question.
                    </p>
                    <ul className='sans-serif gray'>
                        <li><strong>Droit d’accès:</strong> Demandez une copie des informations détenues à votre sujet.</li>
                        <li><strong>Droit de rectification:</strong> Demandez la correction d'informations incorrectes.</li>
                        <li><strong>Droit à l’effacement:</strong> Demandez la suppression de vos données dans certaines circonstances.</li>
                        <li><strong>Droit à la portabilité:</strong> Recevez vos données dans un format structuré.</li>
                        <li><strong>Droit d’opposition:</strong> Vous pouvez vous opposer au traitement de vos données.</li>
                    </ul>
                </InfoSection>

                <InfoSection title="Liens externes vers d’autres sites">
                    <p className='sans-serif gray'>
                        Mon site peut contenir des liens vers d'autres sites non gérés par mes soins. Ces liens sont accompagnés d'une icône <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" /> pour vous indiquer que vous quittez le site. Je ne suis pas responsable des pratiques de confidentialité de ces sites. Consultez leurs politiques avant de les visiter.
                    </p>
                </InfoSection>

                <InfoSection title="Sécurité de vos données">
                    <p className='sans-serif gray'>
                        Je mets en œuvre des mesures de sécurité pour protéger vos informations personnelles. Cependant, aucune transmission de données sur Internet n’est totalement sécurisée.
                    </p>
                </InfoSection>

                <InfoSection title="Divulgation légale">
                    <p className='sans-serif gray'>
                        La divulgation de vos informations peut être nécessaire si la loi l'exige. Je m'engage à limiter cette divulgation aux cas requis par la loi.
                    </p>
                </InfoSection>

                <InfoSection title="Modifications de la politique de confidentialité">
                    <p className='sans-serif gray'>
                        Je me réserve le droit de modifier cette politique à tout moment. Consultez régulièrement cette page pour les mises à jour. <i>Dernière mise à jour de la politique de confidentialité le 30/09/2024.</i>
                    </p>
                </InfoSection>

                <InfoSection title="Contact">
                    <p className='sans-serif gray'>
                        Si vous avez des questions concernant cette politique, n'hésitez pas à me contacter via le <a href="/contact" className="project-links no-hover small-text" target="_blank" rel="noopener noreferrer">formulaire <FontAwesomeIcon icon={faExternalLinkAlt} size="s" /></a> ou par e-mail à<a className="project-links no-hover small-text" target="_blank" rel="noopener noreferrer" href="mailto:contact@anthonytcha.com"> contact@anthonytcha.com <FontAwesomeIcon icon={faExternalLinkAlt} size="s" /></a>.
                    </p>
                </InfoSection>
            </div>
        </section>
    );
};

export default PrivacyPolicy;