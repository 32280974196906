import React, { useState } from 'react';

const Footer = () => {
    const links = [
        { href: "resume", text: "À mon propos" },
        { href: "contact", text: "Contact" },
        { href: "legal-notice", text: "Mentions légales" },
        { href: "privacy-policy", text: "Politique de confidentialité" },
    ];

    const [hoveredLink, setHoveredLink] = useState(null);

    return (
        <footer className='black-background'>
            <div className="footer-container">
                <a href="/">
                    <img src="images/anthonytcha-logo-blk.png" alt="Logo d'Anthony Tcha" className="lighten monogram" />
                </a>
                <div className="links-container">
                    {links.map((link, index) => (
                        <a
                            key={index}
                            href={link.href}
                            className={`display uppercase links ${hoveredLink === index ? 'hovered' : 'no-hover'}`}
                            onMouseEnter={() => setHoveredLink(index)}
                            onMouseLeave={() => setHoveredLink(null)}
                        >
                            {link.text}
                        </a>
                    ))}
                </div>
            </div>
            <div className='copyright-container'>
                <p className="sans-serif copyright center gray">&copy; {new Date().getFullYear()} Anthony Tcha.</p>
                <p className="sans-serif copyright center gray">Tous droits réservés.</p>
            </div>
        </footer>
    );
};

export default Footer;