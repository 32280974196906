import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const InfoSection = ({ title, children }) => (
  <span>
    <h2 className='display medium-text'>{title}</h2>
    <p className='sans-serif gray'>{children}</p>
  </span>
);

const LegalNotice = () => {
  return (
    <section>
      <img src="images/ma-photo-cropped.jpg" alt="Portrait tronqué d'Anthony TCHA" className="project-image" />
      <div className='resume-container'>
        <h1 className='display'>Mentions légales</h1>
        <p className='display yellow small-text'>
          Conformément à la législation en vigueur, notamment la Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, voici les informations légales concernant le site.
        </p>
        <hr />
        <InfoSection title="Informations sur l'éditeur">
          <ul className='sans-serif gray'>
            <li><strong>Nom:</strong> Anthony TCHA</li>
            <li><strong>Statut:</strong> Micro-entrepreneur en graphisme (print et numérique)</li>
            <li><strong>Adresse:</strong> Rue de la Janaie, 35220 CHÂTEAUBOURG</li>
            <li><strong>Téléphone:</strong> 07 77 07 60 51</li>
            <li><strong>Email:</strong> <a className="project-links small-text no-hover" href="mailto:contact@anthonytcha.com" target="_blank" rel="noopener noreferrer">contact@anthonytcha.com <FontAwesomeIcon icon={faExternalLinkAlt} size="s" /></a></li>
            <li><strong>SIREN/SIRET:</strong> 932854813 00015</li>
          </ul>
        </InfoSection>
        <InfoSection title="Hébergement">
          <ul className='sans-serif gray'>
            <li><strong>Hébergeur :</strong> Hostinger</li>
            <li><strong>Adresse :</strong> HOSTINGER INTERNATIONAL LTD, 61 Lordou Vironos Street, 6023 Larnaca, Chypre</li>
            <li><strong>Contact :</strong> <a className="project-links small-text no-hover" href="https://www.hostinger.fr/contact" target="_blank" rel="noopener noreferrer">Contacter Hostinger <FontAwesomeIcon icon={faExternalLinkAlt} size="s" /></a></li>
          </ul>
        </InfoSection>
        <InfoSection title="Propriété intellectuelle">
          Tous les contenus présents sur ce site, y compris les textes, images, graphiques, logos et vidéos, sont protégés par le droit d'auteur. Toute reproduction ou représentation, intégrale ou partielle, de ce site sans autorisation expresse de l’éditeur est interdite.
        </InfoSection>
        <InfoSection title="Cookies">
          Ce site utilise des cookies pour améliorer votre expérience de navigation. Vous pouvez gérer vos préférences concernant les cookies dans les paramètres de votre navigateur.
        </InfoSection>
      </div>
    </section>
  );
};

export default LegalNotice;