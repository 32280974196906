import React from 'react';

const skills = [
  {
    title: 'Création d\'identité visuelle print & num’',
    content: 'Adobe (PS, AI, ID) - Affinity (Designer, Publisher, Photo)'
  }, {
    title: 'Développement de sites responsives',
    content: 'Figma - Wordpress HTML/CSS/Bootstrap/ReactJS/GitHub'
  }, {
    title: 'Réalisation vidéo & motion design',
    content: 'After Effects - Premiere Pro'
  }, {
    title: 'Gestion de projet, testing et recettage',
    content: 'Jira, Microsoft Teams, Asana, Click Up, Monday'
  }, {
    title: 'Audit ergonomique, AB Testing et accessibilité web',
    content: 'Score OPQUAST - 830/1000'
  }, {
    title: 'Storytelling',
    content: 'Twine, FigJam'
  },
];

const experiences = [
  {
    date: 'Sept. 2023 à aujourd\'hui',
    title: 'Scrum master',
    company: '- Datagrowb\', Nantes (44)',
    content: 'Gestion de projet, écriture et suivi de tickets, CDI en temps partiel',
  }, {
    date: 'Sept. 2021 – Sept. 2023',
    title: 'Graphiste polyvalent',
    company: '- Datagrowb\', Nantes (44)',
    content: 'Conception d\'identité visuelle et maquettes de site en alternance',
  }, {
    date: 'Sept. 2020 – Sept. 2021',
    title: 'Technicien audiovisuel',
    company: '- ISM-IAE, Guyancourt (78)',
    content: 'Captation, montage vidéo et motion design, alternance',
  }, {
    date: 'Juin – Juil. 2020',
    title: 'Ouvrier industriel',
    company: '- MX, Acigné (35)',
    content: 'Montage hydraulique en intérim',
  }, {
    date: 'Juin – Juil. 2019',
    title: 'Ouvrier industriel',
    company: '- MX, Acigné (35)',
    content: 'Montage mécanique et retouche peinture en intérim',
  }, {
    date: 'Mai – Juin 2019',
    title: 'Stage de graphisme',
    company: '- Willow Création, Boufféré (85)',
    content: 'Création d\'infographies, logotypes et visuels promotionnels',
  }, {
    date: 'Juil. – Août 2018',
    title: 'Ouvrier agro-alimentaire',
    company: '- BRIDOR, Servon s/ Vilaine (35)',
    content: 'Façonnage et conditionnement de pains et viennoiseries en intérim',
  }, {
    date: 'Février 2016',
    title: 'Stage d\'observation',
    company: '- IFFDEC, Rennes (35)',
    content: 'Participation aux décisions de projet des étudiants',
  }
];

const education = [
  {
    date: '2021 – 2023',
    title: 'Mastère Direction Artistique en alternance',
    content: 'Digital Campus Nantes, St-Herblain (44)',
  }, {
    date: '2020 – 2021',
    title: 'Licence Pro. METWEB en alternance',
    content: 'IUT de Vélizy, Vélizy-Villacoublay (78)',
  }, {
    date: '2018 – 2021',
    title: 'BTS Design Graphique opt. numérique',
    content: 'Lycée Léonard de Vinci, Montaigu (85)',
  }, {
    date: '2017 – 2018',
    title: 'MÀNAA – Mise à niveau en arts appliqués',
    content: 'Lycée Joseph Savina, Tréguier (22)',
  }, {
    date: '2015 – 2017',
    title: 'Bac STI2D opt. ITEC, mention bien',
    content: 'Lycée Pierre Mendès France, Rennes (35)',
  }
];

const languages = [
  {
    title: 'Français',
    content: 'Langue maternelle',
  }, {
    title: 'Anglais',
    content: 'Niveau professionnel - TOEIC : 795/990',
  }, {
    title: 'Hmong',
    content: 'Usage dans le cadre familial',
  }, {
    title: 'Décembre 2019',
    content: 'Guyane française - Voyage culturel (1 mois)',
  }, {
    title: 'Novembre 2017',
    content: 'Irlande - Voyage étudiant (1 semaine)',
  },
];

const hobbies = [
  {
    title: 'Graphisme et dessin',
    content: 'Dessin traditionnel & numérique en autodidacte, Procreate, construction de projet de manga interactif',
  }, {
    title: 'Musique et pratique',
    content: 'Piano en autodidacte, MAO/composition sur FL Studio',
  }, {
    title: 'Vulgarisation scientifique',
    content: 'Technologie, astronomie, physique, biologie, culture générale',
  }, {
    title: 'Culture populaire',
    content: 'Cinéma (Gran Torino, 2008) - Jeux vidéo narratif (Detroit : Become Human, 2018) - Manga (Chainsaw Man, 2019 - L\'Attaque des Titans, 2013 - Fullmetal Alchemist, 2001)',
  }, {
    title: 'Vie associative',
    content: 'Membre de l’Association Culturelle & Traditionnelle Hmong Rennes, création d’identité visuelle, tampon, flyers, bulletins, lettres, participation aux journées commémoratives',
  }, {
    title: 'Sport associative',
    content: 'Natation en autodidacte',
  },
];

const Section = ({ title, items }) => (
  <div className="resume-section">
    <h2 className="display">{title}</h2>
    <hr />
    {items.map((item, index) => (
      <div key={index}>
        <h3 className="display regular inline-block medium-text">{item.title || item.date}</h3>
        {item.company && (
          <span>
            {' '}
            <span className="sans-serif gray">{item.company}</span>
          </span>
        )}
        <p className="sans-serif gray">{item.content}</p>
      </div>
    ))}
  </div>
);

const Resume = () => {
  return (
    <section>
      <img src="images/ma-photo-cropped.jpg" alt="Portrait tronqué d'Anthony TCHA" className="project-image" />
      <div className="resume-container">
        <h1 className="display">À mon propos</h1>
        <Section title="Compétences" items={skills} />
        <Section title="Expériences professionnelles" items={experiences} />
        <Section title="Formation" items={education} />
        <Section title="Langues" items={languages} />
        <Section title="Centres d'intérêt" items={hobbies} />
      </div>
    </section>
  );
};

export default Resume;